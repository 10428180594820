import * as React from 'react';
import PropTypes from 'prop-types';

const SvgMail = ({ className, size }) => {
    const fill = "transparent";
    return (
        <svg
            height={size}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    );
};

SvgMail.defaultProps = {
    className: null,
    size: 24
};

SvgMail.propTypes = {
    // optional
    className: PropTypes.string,
    size: PropTypes.number
};

export default SvgMail;
