import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import SvgMail from "../components/icons/Svg.Mail";
import SvgInstagram from "../components/icons/Svg.Instagram";
import SvgMessenger from "../components/icons/Svg.Messenger";
import Navbar from "../components/Navbar";
import { animateScroll as scroll } from "react-scroll";

import wavinghand from "../assets/img/waving-hand.png";
import laptop from "../assets/img/laptop.png";
import phone from "../assets/img/phone.png";
import telephone from "../assets/img/telephone.png";
import ovd from "../assets/img/projects/whathappenedtogod-v2.png";
import trikko from "../assets/img/projects/trikko.gif";
import unossl from "../assets/img/projects/unossl.gif";
import sneakerdistance from "../assets/img/projects/sneakerdistance.gif";

function Home(){
    const [arrowicon_active, setArrowicon_active] = useState(false);
    const [scrollDir, setScrollDir] = useState("scrolling down");
    const [nav, setNav] = useState(false);

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const isLaptop = useMediaQuery({ query: `(max-width: 1450px)` });

    const scrollDown = () => {
        scroll.scrollMore(window.innerHeight)
    }

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");

            setArrowicon_active(scrollY > lastScrollY)
            setNav(scrollY <= lastScrollY +10)

            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);
        // console.log(scrollDir);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);
    return (
        <div>
            <Navbar hidden={isMobile} />
            {/*<div className="menu-bar">
                <SvgMenu className="beacon"/>
            </div>*/}
            <section className="section-1">
                <h1 className="header">Kacper Kwapisz</h1>
                <p className="subheader">{/*Full-Stack*/} Developer</p>
                <a className={arrowicon_active ? 'arrow-icon open' : 'arrow-icon'} onClick={() => scrollDown()}>
                    <span className="left-bar"/>
                    <span className="right-bar"/>
                </a>
            </section>
            <section className="section-2" id="my-work">
                <h2 className="header">My work<img src={laptop} className="icon"/></h2>
                {/*<div style={{height: 300}}/>*/}
                <div className="column">
                    <div><a href="https://whathappenedtogod.co" target="_blank">
                        <img src={ovd} className="project-single" />
                        <h3>whathappenedtogod</h3>
                        <p>whathappenedtogod is a clothing brand whose main goal is to spread awareness about drug addiction and help people reach their highest potential. I am honored to work on their online store and improve it for their needs.</p>
                        <a href="https://whathappenedtogod.co" target="_blank" className="view">View here</a>
                    </a></div>
                    <div><a href="https://trikkobrand.com/password" target="_blank">
                        <img src={trikko} className="project-single" />
                        <h3>trikko</h3>
                        <p>Trikko. entrusted me with the task of expanding and improving their keyword page. This is a key point in brand development because it allows you to create buzz around your brand, build an audience before launch and generate interest in what you intend to sell. They were pleased with the results.</p>
                        <a href="https://trikkobrand.com/password" target="_blank" className="view">View here</a>
                    </a></div>
                    <div><a href="https://dashboard.unossl.com" target="_blank">
                        <img src={unossl} className="project-single" />
                        <h3>unossl</h3>
                        <p>UnoSSL is a project I started with a friend after we learned that sslforfree no longer exists. It is a completely free online wizard for generating Let's Encrypt certificates. Using Let's Encrypt and maintaining the site with donations.</p>
                        <a href="https://dashboard.unossl.com" target="_blank" className="view">View here</a>
                    </a></div>
                    <div>
                        <img src={sneakerdistance} className="project-single" />
                        <h3>sneakerdistance</h3>
                        <p>SneakerDistance is a project I started to create my own easy-to-use and customizable multi-functional ecommerce store system. It was also a challenge for myself and allowed me to learn new skills.</p>
                    </div>
                    {/*<article className="timeline">
                        <nav className="timeline__nav" style={{top: 190}}>
                            <ul>
                                <li className="active"><span>2017</span></li>
                                <li><span>2018</span></li>
                            </ul>
                        </nav>
                        <section className="timeline__section">
                            <div className="wrapper">
                                <h2 className="milestone">2017</h2>
                                <p>По сути, стратегия позиционирования определяет рекламный макет. Маркетинговая
                                    активность отражает медиаплан, не считаясь с затратами. Общество потребления
                                    переворачивает ролевой поведенческий таргетинг.</p>
                                <p>Рекламный блок, суммируя приведенные примеры, развивает межличностный отраслевой
                                    стандарт. Надо сказать, что бюджет на размещение изменяет из ряда вон выходящий
                                    креатив, отвоевывая рыночный сегмент. Баланс спроса и предложения интегрирована.</p>
                                <h2 className="milestone">2018</h2>
                                <p>По сути, стратегия позиционирования определяет рекламный макет. Маркетинговая
                                    активность отражает медиаплан, не считаясь с затратами. Общество потребления
                                    переворачивает ролевой поведенческий таргетинг.</p>
                            </div>
                        </section>
                    </article>*/}
                </div>
                <h2 className="header second">& more!</h2>
                <p className="subheader">ps: these are just a few of the things I have been working on, I wanted to show only the biggest ones</p>
            </section>
            <section className="section-3" id="contact">
                <h2 className="header">Get in touch<img src={wavinghand} className="icon"/></h2>
                <div className="contact-options">
                    <div className="box mail" style={{width:350,height:350}}>
                        <a href="mailto:m@kapi.dev">
                            <SvgMail size={isMobile ? 250 : isLaptop ? 300 : 350} />
                        </a>
                    </div>
                    <div className="box insta" style={{width:350,height:350}}>
                        <a href="https://instagram.com/basedonthefeelings" target="_blank">
                            <SvgInstagram size={isMobile ? 215 : isLaptop ? 230 : 280} />
                        </a>
                    </div>
                    <div className="box mess" style={{width:350,height:350}}>
                        <a href="https://m.me/ohkacper" target="_blank">
                            <SvgMessenger size={isMobile ? 215 : isLaptop ? 230 : 280} />
                        </a>
                    </div>
                </div>
                {/*<div className="openhours">
                    <p>Available Mon-Fri from 4pm to 1am UTC+2</p>
                </div>*/}

                {/*<h2 className="header second">...or book a call<img src={phone} className="icon"/></h2>
                <div className="calendly">
                    <div className="iframe">
                        <iframe
                            src="https://calendly.com/kapidev/project-consultation?embed_domain=www.kapi.dev&embed_type=Inline&hide_gdpr_banner=1&text_color=000000&primary_color=1a5af0"
                            width="100%" height="100%" frameBorder="0"
                        />
                    </div>
                    <div className="button">
                        <a className="btn" href="https://calendly.com/kapidev/project-consultation" target="_blank">Book now!</a>
                    </div>
                </div>*/}
                <div className="openhours">
                    <p>Available Mon-Fri from 3pm to 11pm UTC+2</p>
                </div>
            </section>
        </div>
    )
};

export default Home;