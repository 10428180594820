import React, {useEffect, useRef, useState} from "react";
// import SvgUser from "./icons/Svg.User";
import { Link, animateScroll as scroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
// import logo from "../logo.svg";

export default function Navbar(props) {
    const [scrollDir, setScrollDir] = useState("scrolling down");
    const [menubar_active, setMenubar_active] = useState(false);
    const [navbar_visible, setNavbar_visible] = useState(false);
    const [slide, setSlide] = useState("0px");
    const { hidden } = props;
    const navbar = useRef(null)

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;
            const currentScrollY = window.scrollY;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");

            if (currentScrollY > lastScrollY) {
                setSlide('-48px');
            } else {
                setSlide('0px');
            }

            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
            // var $nav = document.getElementById("navbar");
            // if(!isMobile) {
                setNavbar_visible(window.scrollY - window.innerHeight + 1 > navbar.current.clientHeight)
            // }
        };

        window.addEventListener("scroll", onScroll);
        // console.log(scrollDir);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);

    return isMobile
        ? (
            <nav id="navbar" ref={navbar}>
                <div className={menubar_active ? 'menu-bar menu-opened' : 'menu-bar'}>
                    <div className="burger-container" onClick={() => setMenubar_active(!menubar_active)}>
                        {/*<div className="menu-bar">
                        <span className="top-bar"/>
                        <span className="middle-bar"/>
                        <span className="bottom-bar"/>
                    </div>*/}
                        <div id="burger">
                            <div className="bar topBar"/>
                            <div className="bar btmBar"/>
                        </div>
                    </div>
                    <div className={navbar_visible ? "icon nav-logo" : "icon nav-logo hide"} onClick={() => scroll.scrollToTop()}>
                        <span>K</span><span className={menubar_active ? "small restletters" : "small restletters hide"}>acper </span>
                        <span>K</span><span className={menubar_active ? "small restletters" : "small restletters hide"}>wapisz</span>
                    </div>
                    <ul className="menu">
                        <li className="menu-item"><a href="/" onClick={() => setMenubar_active(!menubar_active)}>Home</a></li>
                        <li className="menu-item"><a href="#my-work" onClick={() => setMenubar_active(!menubar_active)}>My work</a></li>
                        <li className="menu-item"><a href="#contact" onClick={() => setMenubar_active(!menubar_active)}>Get in touch</a></li>
                    </ul>
                    <div className="shop icon icon-bag"/>
                </div>
            </nav>
        )
        :(
        <nav className={navbar_visible ? 'nav' : 'nav hide'} id="navbar" ref={navbar} /*style={{
            transform: `translate(0, ${slide})`,
            transition: 'transform 90ms linear',
        }}*/ >
            <div className="line"/>
            <div className="nav-content">
                <ul className="nav-items">
                    <li className="nav-item">
                        <Link to="my-work" spy={true} smooth={true} duration={500}>My work</Link>
                    </li>
                </ul>
                <div className="nav-logo" onClick={() => scroll.scrollToTop()}>
                    <span>K</span><span className="small">acper </span>
                    <span>K</span><span className="small">wapisz</span>
                </div>
                {/*<img
                src={}
                className="nav-logo"
                alt="Logo."
                onClick={this.scrollToTop}
            />*/}
                <ul className="nav-items">
                    <li className="nav-item">
                        <Link to="section-3" spy={true} smooth={true} duration={500}>Contact</Link>
                    </li>
                </ul>
            </div>
            <div className="line"/>
        </nav>
    )
};