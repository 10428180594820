import * as React from 'react';
import PropTypes from 'prop-types';

const SvgMessenger = ({ className, size }) => {
    const fill = "#fff";
    return (
        <svg
            height={size}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            viewBox="0 0 512 512"
            stroke="currentColor"
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 00122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 01-53.91 9.93l-58.08-43.47a15 15 0 00-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0153.91-9.93l58.06 43.46a15 15 0 0018 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"
            />
        </svg>
    );
};

SvgMessenger.defaultProps = {
    className: null,
    size: 24
};

SvgMessenger.propTypes = {
    // optional
    className: PropTypes.string,
    size: PropTypes.number
};

export default SvgMessenger;
