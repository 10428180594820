import React from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import Home from "./screens/Home";
import Header from "./components/Header";

const TopHead = () => {
  const {
    location: { pathname },
  } = useHistory();

  return pathname !== "/" ? <Header /> : null;
};

const App = () => {
  return (
      <BrowserRouter>
        <div>
          <TopHead />
          <Switch>
            <Route path="/" exact component={Home} />
          </Switch>
        </div>
      </BrowserRouter>
  );
};

export default App;
